@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');

body {
    margin: 0;
    font-family: 'Inter', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.ant-btn-icon {
    margin-bottom: 0.4vh;
}

.input-group-text {
    background-color: white;
    cursor: pointer;
    color: #4e4e4e;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
}

.form-check-input:checked {
    background-color: #225fb0;
    border-color: #225fb0;
}

.form-check-label {
    color: #4e4e4e;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
}

.mb-05 {
    margin-bottom: 0.15rem !important;
}

svg {
    vertical-align: baseline;
}

body.modal-open > :not(.modal) {
    /*filter: blur(3px);*/
}

.cursor-pinter {
    cursor: pointer;
}

.bd-4 {
    border-radius: 4px;
}

.ant-pagination .ant-pagination-item {
    font-family: 'Inter', sans-serif !important;
}

.w-30 {
    width: 30%;
}

.text-red {
    color: #a5492d;
}

.w-25 {
    width: 25%;
}

.w-10 {
    width: 10%;
}

.w-15 {
    width: 15%;
}

.w-20 {
    width: 20%;
}

.w-95 {
    width: 95%;
}

.bg-danger-100 {
    background-color: #eaa2a4;
}

.bg-danger-66 {
    background-color: #efbabc;
}

.bg-danger-33 {
    background-color: #f5d4d5;
}

.bg-neutral {
    background-color: #d9d9d9;
}

.bg-success-100 {
    background-color: #cde3cd;
}

.bg-success-66 {
    background-color: #bddbbd;
}

.bg-success-33 {
    background-color: #cde3cd;
}

.bg-blue-33 {
    background-color: #CEEDF8;
}

.rounded-left-only {
    border-radius: 4px 0 0 4px;
}

.rounded-right-only {
    border-radius: 0 4px 4px 0;
}

.table-striped > tbody > tr:nth-child(odd) > td,
.table-striped > tbody > tr:nth-child(odd) > th {
    background-color: #f5f5f5;
}

.ant-picker-input > input {
    font-size: 10px !important;
    font-weight: 600;
}

.text-green {
    color: #107c10;
}

.text-red {
    color: #d13438;
}

.text-neutral {
    color: #4E4E4E;
}

.ant-collapse > .ant-collapse-item > .ant-collapse-header {
    padding: 0;
}

.fs-24 {
    font-size: 24px !important;
}

.fs-20 {
    font-size: 20px !important;
}

.fs-10 {
    font-size: 10px;
}

.fs-12 {
    font-size: 12px !important;
}

.fs-14 {
    font-size: 14px !important;
}

.fs-16 {
    font-size: 16px !important;
}

.ant-pagination.ant-pagination-simple .ant-pagination-simple-pager {
    font-size: 10px;
    font-weight: 500;
    color: #4e4e4e;
}

.bg-blue {
    background-color: #dFEDF7;
}

.fw-600 {
    font-weight: 600;
}

.bg-lightgrey {
    background-color: #f5f5f5;
}

.bg-orange {
    background-color: #d47645;
}

.hover-blue {
    background-color: #e0ecfc;
}

.bg-darkblue {
    background-color: #1f78b4;
}

.text-darkblue {
    color: #1f78b4;
}

.bg-danger-pink {
    background-color: #ffdfdf;
}

.scrolling-wrapper {
    overflow-x: scroll;
    overflow-y: hidden;
    white-space: nowrap;
}

.table-container {
    display: table;
    table-layout: fixed;
    width: 100%;
}

.table-scrollable {
    display: table-cell;
    overflow-x: auto;
    width: 100%;
}

.tooltip-line {
    display: flex;
    gap: 1.5vw;
    justify-content: center;

    border-radius: 4px;
    border: 1px solid #225FB0;
    background: #FFF;
    padding: 8px;
    font-size: 10px;
}

.company-stock-code {
    border-radius: 4px;
    background: #225FB0;
    padding: 4px 8px;
    color: #FFF;
}
